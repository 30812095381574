













































































































































































import {
  defineComponent,
  reactive,
  watch,
  ref,
  computed,
  Ref,
  onMounted,
  onUnmounted
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { snakeCase } from 'lodash'
import moment from 'moment'

import {
  defaultObject,
  endpoints,
  showError,
  scrollTop,
  getSortStates,
  toCamelCase,
  scrollBottom,
  matchData,
  frameBusEvent,
  backPage,
  checkIsOnPC,
  mappingKeyAndField
} from 'utils'
import { useQuery, useShowError, useMutation } from 'hooks'
import {
  InputHarvestResult,
  Variety,
  CommonProperty,
  Color,
  Item,
  IState,
  SizeGroup,
  QualityGroup,
  VarietiesByItem,
  SizesBySizeGroup,
  QualitiesByQualityGroup,
  NurseryCompany,
  Quality,
  Size,
  CustomerGroup,
  MappingKeyAndField
} from 'typings'

import {
  CreateCommonForm,
  SelectInputRow,
  ChooseNumber,
  ChooseProperty,
  ChooseColorSingle,
  ConfirmDelete,
  SingleDetailItem
} from 'components'
import { api, framebus } from 'plugins'

interface SetUp {
  id: Ref<number | null>
  requiredRows: Array<string>
  masterLoading: Ref<boolean>
  showDelete: Ref<boolean>
  createLoading: Ref<boolean>
  updateLoading: Ref<boolean>
  deleteLoading: Ref<boolean>
  dialog: Ref<boolean>
  numberDialog: Ref<boolean>
  colorDialog: Ref<boolean>
  dateDialog: Ref<boolean>
  harvestResult: InputHarvestResult
  currentProperty: Ref<string>
  numberDialogTitle: Ref<string>
  colors: Ref<Array<Color>>
  choosePropertyItems: Ref<Array<CommonProperty>>
  selectProperty: (action: string) => void
  getDialogTitle: () => string
  createOrUpdateResult: () => Promise<void>
  chooseProperty: (property: string) => void
  updateHarvestResult: (action: string, item: CommonProperty | Item | number) => void
  confirmDelete: (action: string) => void
  handleSaveAsNew: () => void
  handleAddNew: () => void
  close: () => void
  thisPage: Ref<string>
  isShowAction: Ref<boolean>
  saveCurrentStates: () => void
  sizeGroups: Ref<SizeGroup[]>
  qualityGroups: Ref<QualityGroup[]>
  nurseryCompanies: Ref<NurseryCompany[]>
  getNewData: (mode: string) => void
  itemSelectVariety: Ref<number>
  groupDataMaster: Ref<Array<any>>
  getData: () => void
  customerGroups: Ref<Array<CustomerGroup>>
  isOnPC: boolean
  deleteHarvestResult: () => void
  addEventTyping: () => void
  removeEventTyping: () => void
}

const HarvestResultForm = defineComponent({
  components: {
    CreateCommonForm,
    SelectInputRow,
    ChooseNumber,
    ChooseProperty,
    ChooseColorSingle,
    ConfirmDelete,
    SingleDetailItem
  },
  setup(props, { root, refs }): SetUp {
    scrollTop()
    const thisPage = ref('harvest')
    const isShowAction = ref(false)
    const { $router, $route, $toast, $store } = root
    const { resultId } = $route.params
    let { isDuplicated } = $route.params
    const { harvest_date: harvestDate } = $route.query
    const id = ref(Number(resultId) || null)
    const onSaving = ref(false)
    const isOnPC = checkIsOnPC()
    const LIST_PROPERTY = [
      'harvestDate',
      'item',
      'variety',
      'color',
      'quality',
      'size',
      'quantity',
      'unit',
      'boxes',
      'stems',
      'desiredPrice'
    ]

    const initResult: InputHarvestResult = defaultObject.initHarvestResult
    const requiredRows = Object.keys(initResult).filter(
      (key: string) =>
        key !== 'quantity' &&
        key !== 'boxes' &&
        key !== 'color' &&
        key !== 'desiredPrice' &&
        key !== 'quality' &&
        key !== 'size'
    )

    const listStateStore = ref<IState>($store.state.listStates)
    const listStateSetting = ref<Array<string>>([])
    const listStates = ref<Array<string>>([])
    const groupDataMaster = ref<any[]>([])

    const harvestResult: InputHarvestResult = reactive({
      ...initResult,
      harvestDate: harvestDate || moment().format('YYYY-MM-DD')
    })

    const currentProperty = ref('')
    const dialog = ref(false)
    const numberDialog = ref(false)
    const colorDialog = ref(false)
    const dateDialog = ref(false)
    const showDelete = ref(false)

    const itemSelectVariety = ref(1)

    const choosePropertyItems = ref<any[]>([])

    const productState = computed(() => $store.state.harvest.productState)

    const nextProperty = computed(() => {
      const key = currentProperty.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) + 1
      return listStates.value[indexKey]
    })

    const prevProperty = computed(() => {
      const key = currentProperty.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      const indexKey = listStates.value.indexOf(key) - 1
      return listStates.value[indexKey]
    })

    const items = ref<Array<any>>([])
    const varieties = ref<Array<any>>([])
    const sizes = ref<Array<any>>([])
    const qualities = ref<Array<any>>([])
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])
    const masterLoading = ref(false)

    const { data: resultData, error } = useQuery(() =>
      Number(resultId) ? `${endpoints.HARVEST_RESULT}${resultId}` : null
    )
    useShowError($toast, error)

    const { data: units } = useQuery(endpoints.UNITS)
    const { data: colors } = useQuery(endpoints.COLORS)
    const { data: customerGroups } = useQuery(endpoints.CUSTOMER_GROUP)

    const getVarietyByItem = async () => {
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getData = async (): Promise<void> => {
      masterLoading.value = true
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(endpoints.NURSERY_COMPANY)
        ])

        const [
          { data: qualitiesByQualityGroupData },
          { data: sizesBySizeGroupData },
          { data: nurseryCompaniesData }
        ] = data

        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)
        nurseryCompanies.value = toCamelCase(nurseryCompaniesData)

        const match: any = matchData(
          varietiesByItem.value,
          sizesBySizeGroup.value,
          qualitiesByQualityGroup.value
        )

        items.value = match.items
        varieties.value = match.varieties
        sizes.value = match.sizes
        sizeGroups.value = match.sizeGroups
        qualities.value = match.qualities
        qualityGroups.value = match.qualityGroups
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        masterLoading.value = false
      }
    }

    watch(
      () => $store.state.listStates.harvest,
      () => {
        if ($store.state.listStates.harvest.length === 0) {
          listStateStore.value.harvest = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          defaultObject.listHarvestStates,
          $store.state.listStates.harvest,
          listStateSetting.value
        )
      }
    )

    const getDefaultStates = async () => {
      try {
        const { data } = await api.get(`${endpoints.COMMON}get_setting`)
        listStateSetting.value = toCamelCase(data).setting.states?.harvest
          ? toCamelCase(data).setting.states.harvest
          : []

        if (listStateStore.value.harvest.length === 0) {
          listStateStore.value.harvest = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        // console.log($store.state.listStates)
        listStates.value = getSortStates(
          defaultObject.listHarvestStates,
          $store.state.listStates.harvest,
          listStateSetting.value
        )
      } catch (e) {
        showError(e, $toast, root.$t('order.msg.get_data_failed') as string)
      }
    }

    const [create, { loading: createLoading }] = useMutation('post', endpoints.HARVEST_RESULT)

    const updateEndpoint = computed(() => `${endpoints.HARVEST_RESULT}${id.value}`)

    const [update, { loading: updateLoading }] = useMutation('put', updateEndpoint)
    const [deleteHarvest, { loading: deleteLoading }] = useMutation('delete', updateEndpoint)

    const varietyList = computed(() => {
      const itemId = harvestResult.item?.id

      if (!itemId) return []

      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    watch(resultData, (value) => {
      Object.keys(value).forEach((key) => {
        const keyObject = key as keyof InputHarvestResult
        harvestResult[keyObject] = value[keyObject]
      })
      itemSelectVariety.value = harvestResult.item ? harvestResult.item.id : 1
    })

    const sizeList = computed(() => {
      let result: Array<Size> = []

      const itemSizeGroupId = (harvestResult.item as Item)?.sizeGroup?.id || null
      const varietySizeGroupId = (harvestResult.variety as Variety)?.sizeGroup?.id || null
      const existedSizeId = (harvestResult.size as Size)?.id || null

      // case create new harvestResult
      if (!varietySizeGroupId && !itemSizeGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(sizes.value))
        return result
      }

      if (varietySizeGroupId) {
        // if variety has sizeGroup: filter by variety sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === varietySizeGroupId
        })
      } else if (itemSizeGroupId) {
        // if item has sizeGroup: filter by item sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === itemSizeGroupId
        })
      }

      // case update existed harvestResult
      // add existed size if filtered list doesn't contain that size
      if (existedSizeId) {
        const containCurrentSize = result.some((size) => size.id === existedSizeId)
        if (!containCurrentSize) {
          result.push(harvestResult.size as Size)
        }
      }
      return result
    })

    const qualityList = computed(() => {
      let result: Array<Quality> = []

      const itemQualityGroupId = (harvestResult.item as Item)?.qualityGroup?.id || null
      const varietyQualityGroupId = (harvestResult.variety as Variety)?.qualityGroup?.id || null
      const existedQualityId = (harvestResult.quality as Quality)?.id || null

      // case create new harvestResult
      if (!varietyQualityGroupId && !itemQualityGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(qualities.value))
        return result
      }

      if (varietyQualityGroupId) {
        // if variety has qualityGroup: filter by variety qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === varietyQualityGroupId
        })
      } else if (itemQualityGroupId) {
        // if item has qualityGroup: filter by item qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === itemQualityGroupId
        })
      }

      // case update existed harvestResult
      // add existed quality if filtered list doesn't contain that quality
      if (existedQualityId) {
        const containCurrentQuality = result.some((quality) => quality.id === existedQualityId)
        if (!containCurrentQuality) {
          result.push(harvestResult.quality as Quality)
        }
      }
      return result
    })

    const chooseProperty = (property: string) => {
      currentProperty.value = property
      dialog.value = false
      colorDialog.value = false
      numberDialog.value = false
      dateDialog.value = false

      switch (property) {
        case 'harvestDate':
          dateDialog.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          groupDataMaster.value = varietiesByItem.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizeList.value
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'color':
          colorDialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = units.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualityList.value
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'stems':
          numberDialog.value = true
          break
        case 'desiredPrice':
          numberDialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        default:
      }
    }

    const selectProperty = (action: string): void => {
      harvestResult.stems = harvestResult.stems === 0 ? null : harvestResult.stems
      harvestResult.boxes = harvestResult.boxes === 0 ? null : harvestResult.boxes
      harvestResult.quantity = harvestResult.quantity === 0 ? null : harvestResult.quantity
      harvestResult.price = harvestResult.price === 0 ? null : harvestResult.price
      harvestResult.amount = harvestResult.amount === 0 ? null : harvestResult.amount
      if (action === 'next') {
        chooseProperty(nextProperty.value as string)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value as string)
      }
    }

    const updateHarvestResult = (action: string, item: CommonProperty | Item | number): void => {
      const key = currentProperty.value as keyof InputHarvestResult
      const oldValue = harvestResult[key]
      harvestResult[key] = item

      if (key === 'item') {
        harvestResult.variety = null
        if (
          oldValue?.sizeGroup?.id !== harvestResult[key]?.sizeGroup?.id ||
          harvestResult[key]?.sizeGroup?.id !== harvestResult.size?.sizeGroup?.id
        ) {
          harvestResult.size = null
        }
        if (
          oldValue?.qualityGroup?.id !== harvestResult[key]?.qualityGroup?.id ||
          harvestResult[key]?.qualityGroup?.id !== harvestResult.quality?.qualityGroup?.id
        ) {
          harvestResult.quality = null
        }
        harvestResult.unit = (item as Item).defaultUnit
        itemSelectVariety.value = (item as Item).id
      }
      if (key === 'variety') {
        const compareValue = oldValue ?? harvestResult.item
        if (
          compareValue?.sizeGroup?.id !== harvestResult[key]?.sizeGroup?.id ||
          harvestResult[key]?.sizeGroup?.id !== harvestResult.size?.sizeGroup?.id
        ) {
          harvestResult.size = null
        }
        if (
          compareValue?.qualityGroup?.id !== harvestResult[key]?.qualityGroup?.id ||
          harvestResult[key]?.qualityGroup?.id !== harvestResult.quality?.qualityGroup?.id
        ) {
          harvestResult.quality = null
        }
      }

      selectProperty(action)
    }

    const generateBody = () => {
      return {
        harvestDate: harvestResult.harvestDate,
        itemId: harvestResult.item?.id,
        sizeId: harvestResult.size?.id,
        colorId: harvestResult.color?.id,
        qualityId: harvestResult.quality?.id,
        unitId: harvestResult.unit?.id,
        varietyId: harvestResult.variety?.id,
        stems: harvestResult.stems,
        desiredPrice: harvestResult.desiredPrice,
        quantity: harvestResult.quantity,
        boxes: harvestResult.boxes,
        remark: harvestResult.remark?.trim()
      }
    }

    const replaceUrl = (result: string): void => {
      $router
        .replace({
          params: {
            resultId: result
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const createResult = async (): Promise<boolean> => {
      try {
        const requestBody = generateBody()
        const data = await create(requestBody)
        id.value = data.id

        replaceUrl(id.value ? id.value.toString() : 'create')

        $toast.success(root.$t('common.msg.create_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.create_success'))
      }
      return false
    }

    const updateResult = async (): Promise<boolean> => {
      try {
        const requestBody = generateBody()
        await update(requestBody)
        $toast.success(root.$t('common.msg.update_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
      return false
    }

    const deleteResult = async (): Promise<boolean> => {
      try {
        await deleteHarvest()
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }

      return false
    }

    const deleteHarvestResult = () => {
      // remove keydown event to prevent overwrite delete confirm dialog event
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener('keydown', onTyping)

      showDelete.value = true
    }

    const confirmDelete = async (action: string): Promise<void> => {
      showDelete.value = false
      let success = false
      if (action === 'delete') {
        success = await deleteResult()
      }
      if (success) {
        backPage()
      }

      // Add keydown event
      // eslint-disable-next-line no-use-before-define
      document.addEventListener('keydown', onTyping)
    }

    const close = () => {
      backPage()
    }

    const validateHarvestResult = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const valid = await form.validate()

      return valid
    }

    const createOrUpdateResult = async (): Promise<void> => {
      if (onSaving.value) {
        return
      }
      onSaving.value = true
      const valid = await validateHarvestResult()
      let success = false
      if (!valid) {
        onSaving.value = false
        return
      }

      if (id.value) {
        success = await updateResult()
      } else {
        success = await createResult()
      }
      if (success) {
        framebus.emit(frameBusEvent.UPDATE_CALENDAR, { isUpdate: true })
      }
      if (isOnPC) {
        setTimeout(() => {
          onSaving.value = false
        }, 300)
      } else {
        onSaving.value = false
      }
    }

    const handleSaveAsNew = (): void => {
      id.value = null
      harvestResult.boxes = null
      harvestResult.stems = null
      harvestResult.orderedStems = 0
      harvestResult.orderedBoxes = 0
      replaceUrl('create')
      const form = refs.form as InstanceType<typeof ValidationObserver>
      form.reset()
      scrollTop()
    }

    const handleAddNew = (): void => {
      Object.keys(harvestResult)
        .filter((key) => key !== 'harvestDate')
        .forEach((key) => {
          harvestResult[key] = initResult[key]
        })

      id.value = null
      replaceUrl('create')

      const form = refs.form as InstanceType<typeof ValidationObserver>
      form.reset()
      scrollTop()
    }

    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}.title`) as string
    }

    const numberDialogTitle = computed((): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}`) as string
    })

    watch(
      () => [harvestResult.boxes, harvestResult.quantity],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([boxes, quantity], [preBoxes, preQuantity]) => {
        if (harvestResult.stems === null || (preBoxes !== null && quantity !== null)) {
          harvestResult.stems = Number(harvestResult.boxes * harvestResult.quantity) || null
        }
      }
    )

    const saveCurrentStates = async () => {
      try {
        const setting = { data: $store.state.listStates.harvest, key: 'harvest' }
        await api.put(`${endpoints.COMMON}update_setting_list_states_default`, setting)
        $toast.success(root.$t('common.msg.save_input_state_successful'))
        isShowAction.value = false
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      }
    }
    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        createOrUpdateResult()
      }

      if (id.value) {
        if (event.keyCode === 67) {
          // Press C
          handleSaveAsNew()
        } else if (event.keyCode === 78) {
          // Press N
          handleAddNew()
        } else if (event.keyCode === 27) {
          // Press Esc
          close()
        } else if (event.keyCode === 68) {
          // Press D
          deleteHarvestResult()
        }
      }

      if (!event.shiftKey && !event.ctrlKey && event.keyCode >= 48 && event.keyCode <= 57) {
        const field = mappingKeyAndField.find(
          (e: MappingKeyAndField) => e.keyCode === event.keyCode
        )
        if (field) {
          chooseProperty(field.field)
        }
      }
    }

    watch(
      () => [dialog.value, numberDialog.value, dateDialog.value, colorDialog.value],
      ([showDialog, showNumberDialog, showDateDialog, showColorDialog]) => {
        if (showDialog || showNumberDialog || showDateDialog || showColorDialog) {
          document.removeEventListener('keydown', onTyping)
        } else {
          document.addEventListener('keydown', onTyping)
        }
      }
    )

    onMounted(async () => {
      await getData()
      await getDefaultStates()
      // data from cache
      if (resultData.value) {
        Object.keys(resultData.value).forEach((key) => {
          harvestResult[key] = resultData.value[key]
        })
      }

      // Init data from productState
      const { item, variety, size, quality } = productState.value
      if (item) {
        harvestResult.item = item
        harvestResult.variety = variety
        harvestResult.size = size
        harvestResult.quality = quality
      }

      if (isDuplicated === '1') {
        handleSaveAsNew()
        harvestResult.stems = null
        harvestResult.boxes = null
        harvestResult.desiredPrice = null
        harvestResult.orderedStems = 0
        harvestResult.orderedBoxes = 0
        isDuplicated = '0'
      }
      document.addEventListener('keydown', onTyping)
    })

    onUnmounted(() => {
      $store.commit('resetProductState')
      document.addEventListener('keydown', onTyping)
    })

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData = $store.state.common.newDataMaster
      // itemSelectVariety.value = 1
      switch (mode) {
        case 'item':
          items.value.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
          // eslint-disable-next-line no-restricted-globals
          // parent.postMessage(varietiesByItem.value, '*')
          // send data item variety to iframe
          framebus.emit(frameBusEvent.ITEM_VARIETY, {
            contents: varietiesByItem.value
          })
          itemSelectVariety.value = $store.state.common.newDataMaster.id
          break
        case 'variety':
          listID = varieties.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.value.push({ ...newData })
            varietiesByItem.value.map((varietyByItem: VarietiesByItem, index: number) => {
              if (varietyByItem.id === newData.item.id) {
                varietiesByItem.value[index].varieties.push(newData)
              }
              return null
            })
            $store.commit('setAllVarietyByItem', varietiesByItem.value)
            groupDataMaster.value = varietiesByItem.value
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItem.value
            })
          }
          break
        case 'size':
          listID = sizes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.value.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'quality':
          listID = qualities.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, index: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[index].qualities.push({ ...newData })
              }
              return null
            })
            qualities.value.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        case 'unit':
          listID = units.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            units.value.push({ ...newData })
          }
          break
        default:
      }
      chooseProperty(currentProperty.value)
      updateHarvestResult('next', newData)
    }

    const addEventTyping = (): void => {
      document.addEventListener('keydown', onTyping)
    }

    const removeEventTyping = (): void => {
      document.removeEventListener('keydown', onTyping)
    }

    return {
      id,
      requiredRows,
      masterLoading,
      showDelete,
      createLoading,
      updateLoading,
      deleteLoading,
      harvestResult,
      currentProperty,
      numberDialog,
      numberDialogTitle,
      getDialogTitle,
      dialog,
      colorDialog,
      dateDialog,
      colors,
      chooseProperty,
      choosePropertyItems,
      selectProperty,
      updateHarvestResult,
      createOrUpdateResult,
      confirmDelete,
      handleSaveAsNew,
      handleAddNew,
      close,
      thisPage,
      isShowAction,
      saveCurrentStates,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      itemSelectVariety,
      getNewData,
      groupDataMaster,
      getData,
      customerGroups,
      deleteHarvestResult,
      isOnPC,
      addEventTyping,
      removeEventTyping
    }
  }
})

export default HarvestResultForm
